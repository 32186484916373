import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrRegistrationModulePaymentView',
  computed: {
    ...mapState('payment', ['payment_settings', 'payment_processor', 'payment_processor_widget'])
  }
})

export default class GtrRegistrationModulePaymentView extends Vue {
  data () {
    return {
      loading: false,
      event_uuid: this.$route.params.event_uuid,
      authnet: {
        api_login_id: '',
        sandbox: '0',
        transaction_key: '',
        sandboxItems: [
          {
            text: 'True',
            value: '1'
          },
          {
            text: 'False',
            value: '0'
          }
        ],
        public_client_key: '',
        charge_description_pattern: ''
      },
      stripe: {
        sandbox: '0',
        stripe_key: '',
        stripe_secret: '',
        currency: 'usd',
        sandboxItems: [
          {
            text: 'True',
            value: '1'
          },
          {
            text: 'False',
            value: '0'
          }
        ],
        currencyItems: [
          {
            text: 'usd',
            value: 'usd'
          }
        ],
        charge_description_pattern: ''
      },
      stripecheckout: {
        sandbox: '0',
        webhook_secret: '',
        stripe_key: '',
        stripe_secret: '',
        currency: 'usd',
        sandboxItems: [
          {
            text: 'True',
            value: '1'
          },
          {
            text: 'False',
            value: '0'
          }
        ],
        currencyItems: [
          {
            text: 'usd',
            value: 'usd'
          }
        ],
        charge_description_pattern: ''
      },
      payment_processor_used: '',
      payment_processor_used_display: '',
      payment_processor_widget_uuid: '',
      paymentFields: []
    }
  }

  async mounted () {
    try {
      this.$data.loading = true
      await this.fetchPaymentProcessor()
      this.getPaymentFields(this.$data.payment_processor_used)
      await this.fetchPaymentSettings()
    } catch (error) {
      const message = Vue.prototype.translateError(error)
      if (message) {
        Container.get(Notification).error(message)
      }
    } finally {
      this.$data.loading = false
    }
  }

  @Watch('payment_processor_widget')
  onPaymentProcessorWidgetChange (payload: any) {
    if (payload.length) {
      this.$data.payment_processor_widget_uuid = payload.shift()
    }
  }

  @Watch('payment_processor')
  onPaymentProcessorChange (payload: any) {
    if (payload.length && !this.$data.payment_processor_used) {
      this.$data.payment_processor_used = payload.shift()
      if (this.$data.payment_processor_used === 'stripecheckout') {
        this.$data.payment_processor_used_display = 'Stripe Checkout'
      }
    }
  }

  @Watch('payment_settings')
  onPaymentSettingsChange (payload: any) {
    if (Object.keys(payload.definedProperties)) {
      const settings = payload.definedProperties
      const missing = payload.missingProperties
      const paymentProcessorUsed = this.$data.payment_processor_used
      const paymentFields = this.$data.paymentFields
      if (typeof settings === 'object' && !settings.hasOwnProperty('length')) {
        for (const fieldIndex in paymentFields) {
          const field = paymentFields[fieldIndex]
          this.$data[paymentProcessorUsed][field] = settings[field]
        }
      } else {
        for (const fieldIndex in paymentFields) {
          const field = paymentFields[fieldIndex]
          this.$data[paymentProcessorUsed][field] =
            missing[field] && missing[field].default ? missing[field].default : null
        }
      }
      if (!this.$data[paymentProcessorUsed].currency) {
        this.$data[paymentProcessorUsed].currency = 'usd'
      }
    }
  }

  get configured () {
    if (this.$data.payment_processor_used === 'authnet') {
      return (this.$data.authnet.api_login_id === '' && this.$data.authnet.transaction_key === '' && this.$data.authnet.public_client_key === '')
    }
    if (this.$data.payment_processor_used === 'stripe') {
      return (this.$data.stripe.stripe_key === '' && this.$data.stripe.stripe_secret === '')
    }
    if (this.$data.payment_processor_used === 'stripecheckout') {
      return (this.$data.stripecheckout.stripe_key === '' && this.$data.stripecheckout.stripe_secret === '')
    }
  }

  private getPaymentFields (processor: string) {
    let paymentFields: string[] = []
    switch (processor) {
      case 'authnet':
        paymentFields = [
          'api_login_id',
          'sandbox',
          'transaction_key',
          'public_client_key',
          'charge_description_pattern'
        ]
        break
      case 'payflow':
        paymentFields = ['sandbox', 'user', 'vendor', 'partner', 'pwd', 'currency', 'layout']
        break
      case 'stripe':
        paymentFields = ['sandbox', 'stripe_key', 'stripe_secret', 'charge_description_pattern', 'currency']
        break
      case 'stripecheckout':
        paymentFields = ['sandbox', 'stripe_key', 'webhook_secret', 'stripe_secret', 'charge_description_pattern', 'currency']
        break
    }
    this.$data.paymentFields = paymentFields
  }

  private async fetchPaymentProcessor () {
    try {
      await this.$store.dispatch('payment/getPaymentProcessor', { event_uuid: this.$route.params.event_uuid, form: 'registration' })
    } catch (error) {
      const message = Vue.prototype.translateError(error)
      if (message) {
        Container.get(Notification).error(message)
      }
    }
  }

  private async fetchPaymentSettings () {
    if (!this.$data.payment_processor_widget_uuid) {
      return
    }
    try {
      await this.$store.dispatch('payment/getPaymentSettings', { event_uuid: this.$route.params.event_uuid, widget: this.$data.payment_processor_widget_uuid, form: 'registration' })
    } catch (error) {
      const message = Vue.prototype.translateError(error)
      if (message) {
        Container.get(Notification).error(message)
      }
    }
  }

  private savePaymentSettings () {
    try {
      const data = {
        widget: this.$data.payment_processor_widget_uuid,
        event_uuid: this.$route.params.event_uuid
      }
      const paymentProcessorUsed = this.$data.payment_processor_used
      const paymentFields = this.$data.paymentFields
      for (const fieldIndex in paymentFields) {
        const field = paymentFields[fieldIndex]
        data[field] = this[paymentProcessorUsed][field]
      }
      this.$store.dispatch('payment/savePaymentSettings', { event_uuid: this.$route.params.event_uuid, widget: this.$data.payment_processor_widget_uuid, data })
      Container.get(Notification).success('Payment settings successfully saved.')
    } catch (error) {
      const message = Vue.prototype.translateError(error)
      if (message) {
        Container.get(Notification).error(message)
      }
    }
  }
}
